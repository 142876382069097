import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Model as ClientModel } from 'models/client';
import { CLIENT_ROLE } from 'routes/client/constants';
import * as actions from './actions';

type Client = ClientModel & {
  csrfToken: string;
  auth: {
    isInProgress: boolean;
    isLoggedIn: boolean;
    error: string | null;
  };
};

type ArtistState = Client & { role: CLIENT_ROLE.ARTIST };
type UserState = Client & { role: CLIENT_ROLE.USER };

const initialState = {
  uid: '',
  role: CLIENT_ROLE.UNKNOWN,
  csrfToken: '',
  auth: {
    isInProgress: false,
    isLoggedIn: false,
    error: null,
  },
} as Client;

export const client = createSlice({
  name: 'client',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<actions.Init>) => {
      state.uid = action.payload.uid;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.pictureURL = action.payload.pictureURL;
      state.auth.isLoggedIn = true;
      state.role = action.payload.role;
    },
    setCSRFToken: (state, action: PayloadAction<actions.SetCSRFToken>) => {
      state.csrfToken = action.payload.token;
    },
    update: (state, action: PayloadAction<actions.Update>) => {
      if (action.payload.role === CLIENT_ROLE.USER) {
        const userState = state as UserState;
        userState.userData = action.payload.userData;
      }

      if (action.payload.role === CLIENT_ROLE.ARTIST) {
        const artistState = state as ArtistState;
        artistState.artistData = action.payload.artistData;
      }
    },
    logedInAfterSendingData: (state, action: PayloadAction<boolean>) => {
      state.auth.isLoggedIn = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actions.signIn.pending, (state) => {
      state.auth.isInProgress = true;
    });
    builder.addCase(actions.signIn.fulfilled, (state, action) => {
      state.auth.isInProgress = false;

      if (action.payload.success && action.payload.name) {
        state.auth.isLoggedIn = true;
      }

      if (action.payload.success) {
        state.uid = action.payload.uid;
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.phone = action.payload.phone;
        state.pictureURL = action.payload.pictureURL;
        state.role = action.payload.role;
      }
    });
    builder.addCase(actions.signIn.rejected, (state) => {
      state.auth.isInProgress = false;
    });

    builder.addCase(actions.signOut.pending, (state) => {
      state.auth.isInProgress = true;
    });
    builder.addCase(actions.signOut.fulfilled, (state) => {
      state.auth.isInProgress = false;
      state.auth.isLoggedIn = false;
      state.uid = '';
      state.role = CLIENT_ROLE.UNKNOWN;
      state.auth = {
        isInProgress: false,
        isLoggedIn: false,
        error: null,
      };
    });
    builder.addCase(actions.signOut.rejected, (state) => {
      state.auth.isInProgress = false;
    });
  },
});

export const { init, setCSRFToken, update, logedInAfterSendingData } = client.actions;
export default client.reducer;
