import { createSelector } from 'reselect';

import { RootState } from '..';

export const selectUI = (state: RootState) => {
  return state.ui;
};

export const selectPopUp = createSelector(selectUI, (ui) => {
  return ui.popUp;
});
