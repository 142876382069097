import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Model as SettingsModel } from 'models/settings';
import * as actions from './actions';

type Settings = SettingsModel & {
  lang: 'he' | 'en';
};

const initialState: Settings = {
  lang: 'he',
  texts: {},
  general: { isClosed: false },
};

export const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<actions.Init>) => {
      state.texts = action.payload.texts;
      state.general = action.payload.general;
    },
  },
});

export const { init } = settings.actions;
export default settings.reducer;
