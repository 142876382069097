import * as yup from 'yup';

import { Admin } from 'models/index';
import { ADMIN_ROLE } from 'routes/admin/constants';
import { endpoint, EndpointError, ERROR as ENDPOINT_ERROR } from '../endpoint';
import { ValidationShape, ERROR as VALIDATION_ERROR } from '../validation';

export type CreateParams = Omit<Admin.Model, 'uid'>;
export type CreateResult = Admin.Model;
export type CreateError = EndpointError | { code: ENDPOINT_ERROR.ITEM_ALREADY_EXISTS };

export const createParamsSchema = yup.object().shape({
  role: yup
    .string()
    .oneOf(Object.values(ADMIN_ROLE), VALIDATION_ERROR.INVALID_VALUE)
    .required(VALIDATION_ERROR.REQUIRED),
  password: yup.string().min(6, VALIDATION_ERROR.TOO_SHORT).required(VALIDATION_ERROR.REQUIRED),
  email: yup.string().email(VALIDATION_ERROR.INVALID_VALUE).required(VALIDATION_ERROR.REQUIRED),
  phone: yup.string(),
  name: yup.string().min(2, VALIDATION_ERROR.INVALID_VALUE),
} as ValidationShape<CreateParams>);

export const create = endpoint<CreateParams, CreateResult, CreateError>({
  method: 'POST',
  url: () => `/admin`,
});
