import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector, useTranslation } from 'client/hooks';
import { selectGeneralSettings } from 'client/redux/settings/selectors';
import 'client/styles/index.scss';

import { MAIN_PAGE_ROUTE } from 'client/constants/routes';
import shareImg from 'client/assets/share.png';
import favicon from 'client/assets/favicon/favicon.png';
import faviconApple1 from 'client/assets/favicon/apple-icon-57x57.png';
import faviconApple2 from 'client/assets/favicon/apple-icon-60x60.png';
import faviconApple3 from 'client/assets/favicon/apple-icon-72x72.png';
import faviconApple4 from 'client/assets/favicon/apple-icon-76x76.png';
import faviconApple5 from 'client/assets/favicon/apple-icon-114x114.png';
import faviconApple6 from 'client/assets/favicon/apple-icon-120x120.png';
import faviconApple7 from 'client/assets/favicon/apple-icon-144x144.png';
import faviconApple8 from 'client/assets/favicon/apple-icon-152x152.png';
import faviconApple9 from 'client/assets/favicon/apple-icon-180x180.png';
import favicon2 from 'client/assets/favicon/favicon-16x16.png';
import favicon3 from 'client/assets/favicon/favicon-32x32.png';
import favicon4 from 'client/assets/favicon/favicon-96x96.png';
import faviconAndroid from 'client/assets/favicon/android-icon-192x192.png';
import faviconManifest from 'client/assets/favicon/manifest.json';
import faviconMs from 'client/assets/favicon/ms-icon-144x144.png';

import PopUp from './common/PopUp';
import Main from './pages/Main';

import css from './App.module.scss';
import { setPopUp } from 'client/redux/ui';

const App: React.FC = () => {
  const { translate: seoTexts } = useTranslation('seo');
  const dispatch = useAppDispatch();
  const settings = useAppSelector(selectGeneralSettings);

  React.useEffect(() => {
    removeRootPreloader();
  }, []);

  React.useEffect(() => {
    if (settings.isClosed) {
      dispatch(setPopUp('registration-closed'));
    }
  }, [settings.isClosed, dispatch]);

  const removeRootPreloader = () => {
    const preloadingScreen: HTMLElement | null = document.querySelector('.main-preloader');

    if (preloadingScreen) {
      setTimeout(() => {
        preloadingScreen.style.opacity = '0';
        preloadingScreen.style.visibility = 'hidden';
        preloadingScreen.style.pointerEvents = 'none';
      }, 500);
      setTimeout(() => {
        if (document && document.body) {
          document.body.removeChild(preloadingScreen);
        }
      }, 1500);
    }
  };

  const renderHelmet = () => {
    return (
      <Helmet>
        <title>{seoTexts('title')}</title>
        <link rel="icon" type="image/png" href={favicon} />
        <link rel="apple-touch-icon" sizes="57x57" href={faviconApple1} />
        <link rel="apple-touch-icon" sizes="60x60" href={faviconApple2} />
        <link rel="apple-touch-icon" sizes="72x72" href={faviconApple3} />
        <link rel="apple-touch-icon" sizes="76x76" href={faviconApple4} />
        <link rel="apple-touch-icon" sizes="114x114" href={faviconApple5} />
        <link rel="apple-touch-icon" sizes="120x120" href={faviconApple6} />
        <link rel="apple-touch-icon" sizes="144x144" href={faviconApple7} />
        <link rel="apple-touch-icon" sizes="152x152" href={faviconApple8} />
        <link rel="apple-touch-icon" sizes="180x180" href={faviconApple9} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon2} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon3} />
        <link rel="icon" type="image/png" sizes="96x96" href={favicon4} />
        <link rel="icon" type="image/png" sizes="192x192" href={faviconAndroid} />
        {/* <link rel="manifest" type="manifest" href={faviconManifest} /> */}
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content={faviconMs} />
        <meta name="theme-color" content="#ffffff" />
        <meta name="description" content={seoTexts('description')} />
        <meta property="og:title" content={seoTexts('ogTitle')} />
        <meta property="og:description" content={seoTexts('ogDescription')} />
        <meta property="og:image" content={shareImg} />
      </Helmet>
    );
  };

  return (
    <div className={css.app}>
      {renderHelmet()}
      <Switch>
        <Route exact path={MAIN_PAGE_ROUTE}>
          <Main />
        </Route>
        <Redirect to={MAIN_PAGE_ROUTE} />
      </Switch>
      <PopUp />
    </div>
  );
};

export default App;
