import React from 'react';

import { useTranslation, useAppDispatch, useAppSelector } from 'client/hooks';
import { selectPopUp } from 'client/redux/ui/selectors';

import Icon from '../Icon';

import css from './PopUp.module.scss';
import { setPopUp } from 'client/redux/ui';

interface Props {
  onClose?: () => unknown;
}

const LABELS_MAP = {
  'item-already-exist': 'alreadyIn',
  'registration-closed': 'regIsOver',
  success: 'sent',
  default: '',
};

const PopUp: React.FC<Props> = (props) => {
  const popUp = useAppSelector(selectPopUp);
  const dispatch = useAppDispatch();
  const { translate } = useTranslation('popUp');
  const { onClose } = props;
  const targetLabel = LABELS_MAP[popUp || 'default'];
  const isRegistrationClosedPopUp = popUp === 'registration-closed';

  const onBgClick = React.useCallback(() => {
    dispatch(setPopUp(null));
    onClose?.();
  }, [onClose, dispatch]);

  if (popUp === null) {
    return null;
  }

  return (
    <div className={css.popUp}>
      <div className={css.bg} onClick={!isRegistrationClosedPopUp ? onBgClick : undefined} />
      <div className={css.content}>
        {!isRegistrationClosedPopUp && <Icon type="closePopUp" onClick={onBgClick} className={css.closeIcon} />}
        {translate(targetLabel)}
      </div>
    </div>
  );
};

export default PopUp;
