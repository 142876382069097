export enum CLIENT_ROLE {
  UNKNOWN = 'UNKNOWN',
  USER = 'USER',
  ARTIST = 'ARTIST',
}

export const ANONYMOUS_UID = 'anonymous';

export const REG_EXP = {
  NUMBERS: /^[0-9\b]+$/,
  HEBREW_ONLY_LETTERS: /^[\u0590-\u05FF ,."'-]+$/i,
  HEBREW_WITH_NUMBERS: /^[\u0590-\u05FF 0-9 ,.'-]+$/,
  EMAIL: /[a-zA-Z@. 0-9]/,
};
