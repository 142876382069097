import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// import * as actions from './actions';

type UI = {
  popUp: 'success' | 'item-already-exist' | 'registration-closed' | null;
};

const initialState: UI = {
  popUp: null,
};

export const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setPopUp: (state, action: PayloadAction<'success' | 'item-already-exist' | 'registration-closed' | null>) => {
      state.popUp = action.payload;
    },
  },
});

export const { setPopUp } = ui.actions;
export default ui.reducer;
