import * as yup from 'yup';

import { Lead } from 'models/index';
import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape, ERROR } from '../validation';
import * as Pagination from '../pagination';

export type PaginationParams = Pagination.Params<Omit<Lead.Model, 'id'>, null>;
export type PaginationResult = Pagination.Result<Lead.Model>;
export type PaginationError = EndpointError;

export const paginationParamsSchema = yup.object().shape({
  page: yup.number().positive(ERROR.INVALID_VALUE).required(ERROR.REQUIRED),
  pageSize: yup.number().positive(ERROR.INVALID_VALUE).required(ERROR.REQUIRED),
  search: yup.string(),
  sort: yup.object().shape({
    field: yup.string().oneOf(['title', 'createdAt'], ERROR.INVALID_VALUE),
    direction: yup.string().oneOf(['asc', 'desc'], ERROR.INVALID_VALUE),
  }),
} as ValidationShape<PaginationParams>);

export const paginate = endpoint<PaginationParams, PaginationResult, PaginationError>({
  method: 'POST',
  url: () => `/lead/paginate`,
});
