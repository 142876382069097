import { Admin } from 'models/index';
import { endpoint, EndpointError } from '../endpoint';

export type ReadParams = Pick<Admin.Model, 'uid'>;
export type ReadResult = Admin.Model;
export type ReadError = EndpointError;

export const read = endpoint<ReadParams, ReadResult, ReadError>({
  method: 'GET',
  url: (params) => `/admin/${params.uid}`,
});
