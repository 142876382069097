import * as yup from 'yup';

import { Admin } from 'models/index';
import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape, ERROR as VALIDATION_ERROR } from '../validation';

export type DeleteParams = Pick<Admin.Model, 'uid'>;
export type DeleteResult = void;
export type DeleteError = EndpointError;

export const deleteParamsSchema = yup.object().shape({
  uid: yup.string().required(VALIDATION_ERROR.REQUIRED),
} as ValidationShape<DeleteParams>);

export const deleteAdmin = endpoint<DeleteParams, DeleteResult, EndpointError>({
  method: 'DELETE',
  url: () => `/admin`,
});
