import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth } from 'firebase/auth';

import { auth } from 'endpoints/index';
import { app as firebaseApp } from 'src/firebase-client';
import { ApiResponse } from 'endpoints/endpoint';

export const signOut = createAsyncThunk<
  ApiResponse<auth.SessionOutResult, auth.SessionOutError>,
  auth.SessionOutParams,
  { rejectValue: string }
>('admin/signOut', async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await auth.sessionOut(data).invoke();

    if (response.success) {
      await getAuth(firebaseApp).signOut();
      return response;
    }

    throw new Error('SignOutError');
  } catch (e) {
    return rejectWithValue((e as Error).message);
  }
});
