import * as React from 'react';
import classNames from 'classnames';

import { useResponsive } from 'client/hooks';

import css from './Button.module.scss';

interface Props {
  className?: string;
  type: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  text: string;
}

const Button: React.FC<Props> = (props) => {
  const { className = '', type = 'button', disabled = false, text, ...rest } = props;
  return (
    <button {...rest} className={classNames(css.button, disabled && css.disabled, className)} type={type}>
      {text}
    </button>
  );
};

export default Button;
